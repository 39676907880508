<template>
  <b-card-code title="Modifier Inscription">
    <b-card-text>
      <span>Veuillez modifier les coordonnées du l'inscription </span>
    </b-card-text>
    <!-- form -->
    <validation-observer
      v-if="inscrit"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Identité"
              label-for="Identité"
            >
              <validation-provider
                #default="{ errors }"
                name="Identité"
                rules="required"
              >
                <b-form-input
                  v-model="inscrit.student.id"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Identité"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nom et Prénom de l'étudiant"
              label-for="Nom et Prénom de l'étudiant"
            >
              <validation-provider
                #default="{ errors }"
                name="Nom et Prénom de l'étudiant"
                rules="required"
              >
                <b-form-input
                  v-model="inscrit.student.username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom et Prénom de l'étudiant"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Parcour"
              label-for="Parcour"
            >
              <validation-provider
                #default="{ errors }"
                name="parcour"
                rules="required"
              >
                <v-select
                  v-model="inscrit.parcour.title"
                  :clearable="false"
                  :options="parcours"
                  label="title"
                  placeholder="parcour"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Group"
              label-for="Group"
            >
              <validation-provider
                #default="{ errors }"
                name="group"
                rules="required"
              >
                <v-select
                  v-model="inscrit.group"
                  :clearable="false"
                  :options="groupes"
                  label="group_code"
                  placeholder="group"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Niveau"
              label-for="Niveau"
            >
              <validation-provider
                #default="{ errors }"
                name="Niveau"
                rules="required"
              >
                <b-form-input
                  v-model="inscrit.level"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Niveau"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Semester"
              label-for="Semester"
            >
              <validation-provider
                #default="{ errors }"
                name="semester"
                rules="required"
              >
                <v-select
                  v-model="inscrit.semester"
                  :clearable="false"
                  :options="semesters"
                  label="name"
                  placeholder="semester"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editInscription"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,

  },
  data() {
    return {
      password: '',
      inscrit: {},
      required,
      parcours: [],
      groups: [],
      semesters: ['semester 1', 'semester 2'],
      parcourID: '',
    }
  },
  computed: {
    groupes() {
      // eslint-disable-next-line eqeqeq
      // return this.groups.filter(item => console.log('checking the inscrit', this.parcourID, item.parcour.id))
      return this.groups.filter(item => item.parcour.id === this.parcourID)
      // return this.groups.filter(item => item.parcours.id == this.inscrit.parcour.id)
    },
  },
  created() {
    this.getGroups()
    this.getParcours()
    this.getInscriptions()
    this.getStudent()
  },
  methods: {
    async getParcours() {
      this.load = 'true'
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
      this.load = 'false'
    },
    async getGroups() {
      this.load = 'true'
      const response = await axios.get('/api/groups/')
      this.groups = response.data
      this.load = 'false'
    },
    async getInscriptions() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/inscriptions/detail/${id}`)
      this.inscrit = response.data
      this.parcourID = this.inscrit.parcour.id
      this.load = 'false'
    },
    async editInscription() {
      this.$refs.simpleRules.validate().then(async success => {
        const { id } = this.$route.params
        if (success) {
          try {
            await axios
              .put(
                `/api/inscriptions/update/${id}/`,
                {
                  student: this.inscrit.student.id,
                  parcour: this.inscrit.parcour.id,
                  group: this.inscrit.group.id,
                  redouble: this.inscrit.redouble,
                  semester: this.inscrit.semester,
                  level: this.inscrit.level,
                },
              )
            this.$router.push('/inscriptions')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Inscription Modifié avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
